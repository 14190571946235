import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import { Avatar } from "@material-ui/core";
import styles from "assets/jss/nextjs-material-kit/pages/landingPageSections/teamStyle.js";
import customStyles from "assets/jss/custom/customStyles.js";

import marieImage from "assets/img/testimonials/sugar-babe-linz.jpg";
import robertImage from "assets/img/testimonials/sugar-daddy-wien.jpg";
import katjaImage from "assets/img/testimonials/sugar-babe-innsbruck.jpg";

import { Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const useStyles = makeStyles({
  ...styles,
  ...customStyles,
});

export default function TeamSection() {
  const classes = useStyles();

  const testimonials = [
    {
      name: "Anna M.",
      age: "25",
      location: "Linz",
      image: marieImage.src,
      alt: "Sugarbabe Erfahrungen",
      quote:
        "Eine Studienkollegin hat mit von ihren positiven Erfahrungen auf getasugar berichtet. Aus Neugier habe ich mich dann auch angemeldet. Ich war überrascht, wie viele spannende Kontakte ich dort kennenlernen konnte. Aktuell treffe ich mich regelmäßig mit einem erfolgreichen und spendablen Geschäftsmann aus Salzburg.",
    },
    {
      name: "Stefan H.",
      age: "39",
      location: "Wien",
      image: robertImage.src,
      alt: "Sugardaddy Erfahrungen",
      quote:
        "Ich liebe es, Zeit mit interessanten Frauen zu verbringen und mit ihnen die schönen Seiten des Lebens zu genießen. Egal ob in guten Restaurants bei einem Wein oder während gemeinsamen Reisen. Auf getasugar finde ich Damen, die einen Gentleman und ein Abenteuer zugleich suchen. Gerne bin ich für mein Sugar Babe genau das.",
    },
    {
      name: "Claudia L.",
      age: "31",
      location: "Innsbruck",
      image: katjaImage.src,
      alt: "Sugardating Erfahrungen",
      quote:
        "Ich gebe zu, dass mein Stereotyp von Sugar Daddy so gar nicht auf die Männer zutraf, die ich dank getasugar.at kennenlernen durfte. Glücklicherweise. Auf dieser Website hatte ich aufregende Chats mit Charakteren, die wissen was sie möchten. Was folgte waren verschiedene Dates und viele großartige Momente. ",
    },
  ];

  return (
    <GridContainer alignItems="flex-start">
      <GridItem xs={12} className={classes.hasTextCentered}>
        <h2 className={classes.h2}>Das sagen unsere Mitglieder</h2>
        <p className={classes.isTeaser}>
          Sugar Daddys und Sugar Babes sprechen über ihre Erfahrung auf getasugar.at
        </p>
      </GridItem>

      <GridItem xs={12}>
        <Swiper
          slidesPerView="1"
          breakpoints={{
            480: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
          modules={[Pagination, A11y]}
          pagination={{ clickable: true }}
        >
          {testimonials.map((testimonial) => (
            <SwiperSlide>
              <Card
                plain
                style={{ padding: 10, paddingTop: 0, paddingBottom: 0 }}
              >
                <CardHeader image style={{ textAlign: "center" }}>
                  <Avatar
                    src={testimonial.image}
                    alt={testimonial.name}
                    style={{
                      width: "9em",
                      height: "9em",
                      display: "inline-block",
                    }}
                  />
                </CardHeader>
                <CardBody style={{ padding: "0" }}>
                  <h3
                    className={classNames(
                      classes.isBold,
                      classes.hasTextCentered
                    )}
                  >
                    {testimonial.name}
                  </h3>
                  <h6
                    className={classNames(
                      classes.isGrey,
                      classes.hasTextCentered
                    )}
                  >
                    {testimonial.age} Jahre, aus {testimonial.location}
                  </h6>

                  <p className={classes.hasTextCentered}>
                    "{testimonial.quote}"
                  </p>
                </CardBody>
              </Card>
            </SwiperSlide>
          ))}
        </Swiper>
      </GridItem>

      <GridItem xs={12} style={{ marginBottom: "1em", marginTop: "2em" }}>
        <p
          className={classNames(
            classes.hasTextCentered,
            classes.isSmall,
            classes.isGrey
          )}
        >
          Wir schützen eure Privatsphäre! Deshalb findet ihr an dieser Stelle ausschließlich Pseudonyme und beispielhaft Fotos.
        </p>
      </GridItem>
    </GridContainer>
  );
}
