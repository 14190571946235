import React, { useState, useRef } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { useRouter } from "next/router";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";

import Head from "next/head";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Hidden from "@material-ui/core/Hidden";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";

import styles from "assets/jss/nextjs-material-kit/pages/landingPage.js";
import customStyles from "assets/jss/custom/customStyles.js";

// Sections for this page
import TeamSection from "pages-sections/LandingPage-Sections/TeamSection.js";
//components

import CTABlock from "components/Content/CTABlock";
import USPBlock from "components/Content/USPBlock.js";

//header image
import headerImage1 from "assets/img/header/sugar-daddy-oesterreich.jpg";
import mobileHeaderImage1 from "assets/img/header/sugar-daddy-oesterreich-mobile.jpg";

import sneakSearchProfiles from "assets/img/sugar-daddy-website.jpg";

import sneakExplore from "assets/img/sugar-daddy-finden.png";
import sneakMessenger from "assets/img/sugar-daddy-chat.png";
import sneakEvents from "assets/img/sugar-daddy-treffen.png";
import { Grid } from "@material-ui/core";

//Styles

const headerImages = [
  {
    desktop: headerImage1.src,
    mobile: mobileHeaderImage1.src,
    alt: "Sugardaddy Sugarbabe",
  }
];

const headerImage =
  headerImages[Math.floor(Math.random() * Math.floor(headerImages.length))];

//const headerImage = headerImages[2]

const dashboardRoutes = [];

const useStyles = makeStyles({
  ...styles,
  ...customStyles,
  rowImage: {
    backgroundImage: `url(${headerImage1.src})`,
    backgroundSize: "cover",
    backgroundPosition: "top center",
  },
});

export async function getStaticProps() {
  const tagCities = [
    "Wien",
    "Graz",
    "Linz",
    "Salzburg",
    "Innsbruck",
    "Klagenfurt am Wörthersee",
    "Villach",
    "Wels",
    "St. Pölten"
  ];

  return {
    props: {
      tagCities,
    },
    revalidate: 300,
  };
}

export default function LandingPage(props) {
  const classes = useStyles();
  const router = useRouter();
  const { tagCities, blogTeaser, ...rest } = props;
  const [type, setType] = useState<"0" | "1">("0");

  const purpleCTAContainer = (
    <div className={classNames(classes.rowOdd, classes.isWhite)}>
      <CTABlock />
    </div>
  );

  const imageCTAContainer = (
    <div
      className={classNames(
        classes.rowImage,
        classes.isWhite,
        classes.hasMarginTop10
      )}
    >
      <div className={classes.container}>
        <h2>Jetzt anmelden</h2>
      </div>
      <CTABlock />
    </div>
  );

  const headerSignup = (
    <div className={classes.container}>
      <Grid container alignItems="center" justify="space-between">
        <Grid item xs={12} sm={8} md={5} style={{ padding: 15, marginTop: 35 }}>
          <div className={classes.signUpBox}>
            <h1 className={classes.title}>
              Sugar Daddys und Sugar Babes in Österreich
            </h1>
            <div>
              <Button
                color="primary"
                fullWidth
                onClick={() => router.push("/register?type=0")}
              >
                ich suche ein Sugar Babe
              </Button>
              <Button
                color="primary"
                fullWidth
                onClick={() => router.push("/register?type=1")}
              >
                ich suche einen Sugar Daddy
              </Button>
            </div>

            <Hidden xsDown>
              <p
                className={classNames(
                  classes.hasTextCentered,
                  classes.hasMarginTop10
                )}
              >
                <small>
                  Bereits bei getasugar?{" "}
                  <a
                    href={process.env.NEXT_PUBLIC_FRONTEND_BASEURL + "/login"}
                    className={classNames(classes.isPrimary, classes.isBold)}
                  >
                    » Login
                  </a>
                </small>
              </p>
            </Hidden>
            <div>
              <ul className={classes.checkmarkList}>
                <li>
                  <strong>Geprüfte Mitglieder </strong>
                   - Viele verifizierte Profile
                </li>
                <li>
                  <strong>Bleibe anonym</strong>
                   - Keine Realnamen Pflicht
                </li>
                <li>
                  <strong>Sicher flirten</strong>
                  - Alles SSL verschlüsselt
                </li>
              </ul>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={7}></Grid>
      </Grid>
    </div>
  );

  return (
    <div>
      <Header
        color="primary"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks adaptiveFold={true} />}
        fixed
        {...rest}
      />
      <Parallax
        responsive
        image={headerImage.desktop}
        mobileImage={headerImage.mobile}
        alt={headerImage.alt}
        style={{ paddingBottom: 20 }}
      >
        <Hidden xsDown>{headerSignup}</Hidden>
      </Parallax>

      <div className={classes.main}>
        <Hidden smUp>{headerSignup}</Hidden>
        <USPBlock />

        <div className={classNames(classes.container, classes.hasMarginTop10)}>
          <Grid container alignItems="flex-start">
            <Grid item xs={12} className={classes.hasTextCentered}>
              <h2 className={classes.h2}>getasugar - Sugar Dating für Österreich</h2>
              <p
                className={classNames(
                  classes.isTeaser,
                  classes.isTeaserFullwidth
                )}
              >
                Auf getasugar.at findest du zahlreiche Mitglieder auf der Suche nach einem Sugar Daddy 
                oder einem Sugar Babe. Prickelnde Dates und besondere Momente warten auf dich.
              </p>

              <img
                src={sneakSearchProfiles.src}
                alt="Sugar Daddy Mobile App"
                className={classes.contentImage}
              />

              <p>
                Die Registrierung auf unserer Sugar Daddy Website ist komplett kostenlos und verpflichtet dich zu nichts. 
                Auch wenn du kein VIP Mitglied wirst, kannst du dein Dating Profil erstellen und andere Mitglieder auf 
                dich aufmerksam machen. Das geht zum Beispiel indem du interessanten Sugar Daddys oder Sugar Babes ein 
                digitales Busserl sendest. 
              </p>
              <p>
                Das ist einmalig: Frauen auf getasugar können mit unserem „Catch of the Day“ übrigens täglich eine Nachricht 
                gratis versenden. Das Beantworten von Nachrichten ist für Sugar Babes und Sugar Daddys immer kostenfrei.
              </p>
            </Grid>
          </Grid>
        </div>
        <div className={classes.rowGrey}>
          <div
            className={classNames(classes.container, classes.hasMarginTop10)}
          >
            <Grid container alignItems="center" justifyContent="center">
              <Grid
                item
                xs={12}
                sm={6}
                md={5}
                className={classNames(
                  classes.hasTextCentered,
                  classes.switchOrderMobilePos2
                )}
              >
                <img src={sneakExplore.src} alt="Sugardaddys" height="320" />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={5}
                className={classes.switchOrderMobilePos1}
              >
                <h3>Spannende Profile in deiner Nähe</h3>
                <p>
                  Niveauvolle Sugar Daddys aus Wien, Graz, Linz oder Salzburg sind auf getasugar um attraktive Frauen zu daten. Finde jetzt dein Sugar Date in deine Stadt.
                </p>
              </Grid>
            </Grid>
          </div>
        </div>

        <div>
          <div
            className={classNames(classes.container, classes.hasMarginTop10)}
          >
            <Grid container alignItems="center" justifyContent="center">
              <Grid
                item
                xs={12}
                sm={6}
                md={5}
                className={classes.switchOrderMobilePos1}
              >
                <h3>Kennenlernen leicht gemacht</h3>

                <p>
                  Die getasugar Events sind perfekt für knisternde Dates und gemeinsame Erlebnisse zwischen Sugar Daddy und Sugar Babe. 
                </p>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={5}
                className={classNames(
                  classes.hasTextCentered,
                  classes.switchOrderMobilePos2
                )}
              >
                <img
                  src={sneakEvents.src}
                  alt="Sugardaddy treffen"
                  height="320"
                />
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={classes.rowGrey}>
          <div
            className={classNames(classes.container, classes.hasMarginTop10)}
          >
            <Grid container alignItems="center" justifyContent="center">
              <Grid
                item
                xs={12}
                sm={6}
                md={5}
                className={classNames(
                  classes.hasTextCentered,
                  classes.switchOrderMobilePos2
                )}
              >
                <img
                  src={sneakMessenger.src}
                  alt="Sugardaddys kennenlernen"
                  height="320"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={5}
                className={classes.switchOrderMobilePos1}
              >
                <h3>Integrierte Chat Funktion</h3>

                <p>
                  Lerne im Chat stilvolle Männer oder Frauen ausführlich kennen, tausche private Bilder aus und verabrede dich zu einem Treffen vor Ort.
                </p>
              </Grid>
            </Grid>
          </div>
        </div>

        {purpleCTAContainer}

        <div className={classNames(classes.container, classes.hasMarginTop10)}>
          <TeamSection />
        </div>

        {purpleCTAContainer}

        <div className={classNames(classes.container, classes.hasMarginTop10)}>
          <Grid container alignItems="stretch">
            <Grid item xs={12} className={classes.hasTextCentered}>
              <h2 className={classes.h2}>Sugar Daddys und Sugar Babes in Österreich</h2>
              <p
                className={classNames(
                  classes.isTeaser,
                  classes.isTeaserFullwidth
                )}
              >
                In viele Städten Österreichs warten prickelnde Sugar Dates auf dich
              </p>
            </Grid>
            <Grid
              item
              xs={12}
              className={classNames(
                classes.hasTextCentered,
                classes.hasMarginTop10
              )}
            >
              {tagCities.map((city) => (
                <Chip
                  label={city}
                  color="primary"
                  className={classes.primaryChip}
                  onClick={() => router.push("/register")}
                />
              ))}
            </Grid>
          </Grid>
        </div>

        <div className={classes.container}>
          <Grid container alignItems="stretch">
            <Grid item xs={12} className={classes.hasTextCentered}>
              <h2 className={classes.h2}>Sugar Daddys und Sugar Babes aus Österreich</h2>

              <p>Auf getasugar findest du{' '}<b>zahlreiche Sugar Daddys</b> und Sugar Babes 
                aus Österreich. Ob in Wien, Salzburg, Graz oder Linz: In allen großen 
                österreichischen Städten suchen{' '}<b>attraktive Sugar Babes</b> nach 
                erfolgreichen Männern für aufregende Dates. Die Stadt- und 
                Umkreissuche von getasugar hilft dir Profile in deiner Nähe zu finden. So 
                kannst du als Sugar Daddy mit attraktiven Österreicherinnen aus dem 
                Burgenland, Kärnten, der Steiermark, Tirol oder Vorarlberg chatten und 
                flirten.</p>

              <h2 className={classes.h2}>Warum getasugar als Sugardaddy Portal?</h2>

              <p>Egal ob du einen Sugardaddy oder ein Sugarbabe suchst – wir sind uns 
                sicher, dass du von getasugar begeistert sein wirst. Auf unserem 
                {' '}<b>Sugardaddy Portal</b> findest du nicht nur viele Mitglieder aus Österreich, 
                sondern kannst auch eine Vielzahl an Funktionen kostenfrei nutzen. Als 
                Frau sendest du mit unserem sogenannten „Catch of the Day“ sogar 
                {' '}<b>einmal täglich kostenfreie Flirt Nachrichten</b> an einen Sugardaddy.
                Die Registrierung auf getasugar.at ist absolut kostenfrei und mit keinerlei
                Kosten oder Abos verbunden. Kostenpflichtige Zusatzleistungen sind 
                immer deutlich gekennzeichnet. 
                Ein hoher Anteil von auf Echtheit überprüften, verifizierten Profilen sorgt für Sicherheit 
                gegen mögliche Fake User. Dafür prüft das getasugar Team jedes hinterlegte Foto und jeden 
                Ausweis persönlich. Eine Verifikation ist nicht obligatorisch, verbessert aber deine Flirt 
                Chancen deutlich.</p>

              <p>Die Sicherheit deiner Daten ist uns wichtig! Als Unternehmen mit Sitz in 
                der EU werden deine Daten nach strengem europäischen Datenschutz 
                gespeichert und stets SSL verschlüsselt. Anders als manche andere 
                Sugardaddy Website oder App veröffentlichen wir keine Daten oder Fotos
                außerhalb unseres Mitglieder-Bereichs oder machen Informationen 
                deines Dating Profil für Suchmaschinen zugänglich. Wenn du möchtest, 
                kannst du dich auch anonym bei uns registrieren. Eine Echtnamen-Pflicht
                besteht bei getasugar.at nämlich nicht.</p>

              <h2 className={classes.h2}>Ein Sugar Babe kennenlernen</h2>

              <p>Vielen Männern aus Österreich gefällt der Gedanke eine <b>attraktive, 
                junge Frau</b> kennenzulernen und sind gerne bereit mit ihnen den eigenen
                Status oder Luxus zu teilen. Profitiert eine Frau von einem vermögenden 
                oder erfolgreichen Mann, sei es durch Geschenke, gemeinsame Reisen 
                oder auch Taschengeld, spricht man bei ihr von einem{' '}<b>Sugar Babe</b>. 
                Wenn du als Sugar Daddy ein Sugar Babe kennenlernen willst, ist eine 
                Sugardaddy Website wie getasugar.at die richtige Adresse für dich. 
                Zahlreiche Frauen suchen hier den Kontakt zu spendablen, erfolgreichen 
                und gestandenen Männern zu denen sie aufschauen können.</p>

              <h2 className={classes.h2}>Einen Sugar Daddy finden</h2>

              <p>Es ist der Traum von vielen Frauen, einen erfolgreichen oder sogar 
                reichen Mann kennenzulernen. Gerne möchten sie von ihm wie eine Lady 
                behandelt werden, sich verwöhnen lassen und von ihm verführt werden. 
                All dies verkörpert der{' '}<b>Sugar Daddy</b>. Ein Sugar Daddy liest seiner meist 
                jüngeren Frau in seinem Leben sämtliche Wünsche von den Lippen ab, 
                entführt sie in neue Welten voll von Luxus und ist gerne Mentor in 
                sämtlichen ihrer Lebensfragen. Wenn du einen{' '}<b>erfahrenen oder 
                wohlhabenden Mann</b> finden möchtest, solltest du getasugar 
                ausprobieren. Hier suchen spendable Sugar Daddys nach einmaligen 
                Dates, prickelnden Affairen, aber auch nach langfristigen Beziehungen.</p>

              <h2 className={classes.h2}>Wo gibt es die meisten Sugar Daddys in Österreich?</h2>

              <p>Da Sugar Daddys in der Regel sehr erfolgreiche Geschäftsmänner oder 
                Unternehmer sind, findest du sie meist in den großen Städten 
                Österreichs. Allen voran natürlich in unserer Hauptstadt{' '}<b>Wien</b>. 
                Insbesondere im 1. Wiener Bezirk leben besonders viele reiche Wiener. 
                Schaut man sich die Statistik an, gilt{' '}<b>Vorarlberg</b> als das Bundesland mit 
                den im Schnitt höchsten Einkommen. Die reichsten Gemeinden wiederum
                sind Sattledt, Pfaffing und Geinberg in{' '}<b>Oberösterreich</b>. Wenn du also 
                auf der Suche nach einem Flirt mit einem wohlhabenden Sugar Daddy 
                bist, schau dich also nicht nur in den österreichischen Großstädten um 
                sondern auch in den Gemeinden.</p>
            </Grid>
          </Grid>
        </div>

      </div>

      <Footer />
    </div>
  );
}
